body {
  background-color: #20101A;
  overflow-x: hidden;
}

.app {
  min-height: 500vh;
}

.hero {
  text-align: center;
  background-image: url(./assets/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100vw;
  height: calc(100vh + 13rem);
  position: relative;
  overflow: hidden;
}

.background-corridor-container {
  position: relative;
  margin-top: -22rem;
  height: 83vw;
}

.background-corridor {
  background-image: url(./assets/background-2.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100vw;
  height: 83vw;
  position: absolute;
  left: 0;
  image-rendering: pixelated;
}

.logo {
  background-image: url(./assets/logo.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 10rem;
  height: 2.7rem;
  margin-left: 0.5rem;
  image-rendering: pixelated;
}

.logo-mark {
  background-image: url(./assets/logo-mark.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 2.7rem;
  height: 2.7rem;
  image-rendering: pixelated;
}

.navigation {
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 10;
  top: -1px;
  padding-bottom: 2rem;
  background-image: linear-gradient(0deg, transparent, rgb(33 19 23 / 70%), #20101A);
}

.menu {
  display: flex;
}

.menu-mobile {
  display: none;
}

.menu-item {
  font-size: 1.25rem;
  color: #9b9894;
  background-color: #463a3d;
  padding: 0.4rem 1rem;
  margin: 0.5rem;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-decoration: none;
}

.call-to-action {
  position: fixed;
  bottom: 1.75rem;
  right: 1.75rem;
  color: white;
  background-color: #6b3933;
  width: 30rem;
  max-width: calc(100vw - 3.5rem);
  padding: 1.2rem 1.1rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.call-to-action .button {
  background-color: #ac753d;
  padding: 0.5rem 1rem;
  color: #ffeab8;
  border-radius: 5px;
  font-size: 1.6rem;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-transform: uppercase;
  border: 2px solid #ac753d;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-out;
}

.call-to-action .button:hover {
  transform: scale(1.1);
}

.call-to-action .button .button-text {
  position: relative;
  z-index: 10;
}

.call-to-action .button .button-shimmer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6b3933;
  width: 100%;
  height: 100%;
  mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/500% 100%;
  background-repeat: no-repeat;
  animation: shimmer 5s infinite;
}

.call-to-action .subtitle {
  opacity: 0.5;
  font-size: 1.2rem;
  margin-bottom: 0.15rem;
  color: #ebb976;
}

.call-to-action .title {
  text-transform: uppercase;
  color: #ebb976;
}

.menu-item.icon {
  padding: 0.4rem;
}

.menu-item:hover {
  background-color: #352a2d;
}

.menu-item.primary {
  color: rgb(33 19 23);
  background-color: #f6931d;
}

.menu-item.primary:hover {
  background-color: #d75334;
}

.hero .shadow {
  position: absolute;
  bottom: -1px;
  height: 15rem;
  width: 100vw;
  background-image: linear-gradient(180deg, transparent, rgb(33 19 23 / 70%), #20101A);
}

.hero canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.content {
  font-size: 2.75rem;
  color: #f5e2b5;
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  text-shadow: -3px 3px 0px #0000007d;
}

.content.after-hero {
  top: -15rem;
  position: relative;
  z-index: 2;
}

.capital-letter {
  font-size: 8rem;
  line-height: 0;
  float: left;
  margin-top: 2.75rem;
  margin-bottom: 1rem;
  margin-right: 0.65rem;
}

.capital-letter.mega {
  font-size: 10rem;
  margin-top: 3.75rem;
}

.mint-box {
  padding: 1rem;
  border: 6px solid #463a3d;
  max-width: 800px;
  margin: auto;
  font-size: 3rem;
  color: #F7931E;
  text-align: center;
  display: flex;
}

.mint-box .characters {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.mint-box .characters .character {
  font-size: 1.75rem;
}

.mint-box .characters .character-image {
  width: 14rem;
  height: 14rem;
  background-repeat: no-repeat;
  background-size: 100%;
  image-rendering: pixelated;
  background-image: url('./assets/dungeonblazer.gif');
}

.mint-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 1rem;
  padding-left: 2rem;
}

.button {
  text-decoration: none;
  user-select: none;
  text-shadow: none;
  background-color: #463a3d;
  color: #9b9894;
  font-size: 2rem;
  padding: 0.75rem;
  text-align: center;
}

.button.mint {
  margin-top: 2rem;
}

.button-group {
  display: flex;
  width: 100%;
}

.button-group .button {
  flex: 1;
}

.button.social {
  background-color: #ebb976;
  color: #190e15;
}

hr {
  border: 2px solid rgb(245 226 181 / 20%);
  width: 100%;
}

.roadmap li {
  margin-bottom: 2rem;
  list-style-type: square;
}

.rainbow {
  color: #BADFDD;
  display: inline-block;
  animation: rainbow 3s cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite;
  font-style: italic;
  margin-left: -0.05em;
}

.rainbow-background {
  background-color: #BADFDD;
  animation: rainbow-background 3s cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite;
}

.rainbow-background-weird {
  background-color: #f6931d;
  animation: rainbow-background-2 3s cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite;
}

.rainbow-background-reverse {
  background-color: #BADFDD;
  animation: rainbow-background 3s cubic-bezier(0.55, 0.06, 0.68, 0.19) infinite reverse;
}

.blue {
  color: #BADFDD;
}

.highlight {
  color: #f7931e;
}

.twitter-icon {
  width: 1.3rem;
  height: 1.3rem;
  background-size: 100%;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-image: url('./assets/social-twitter.png')
}

.discord-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-image: url('./assets/social-discord.png');
}


p, li {
  line-height: 1.1;
  hyphens: auto;
  text-align: justify;
}

h1 {
  font-size: 4rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 740px) {
  .menu {
    display: none;
  }

  .menu-mobile {
    display: flex;
  }

  .background-corridor {
    background-size: cover;
    height: 90vh;
  }

  .background-corridor-container {
    position: relative;
    margin-top: -22rem;
    height: 90vh;
  }

  h1 { 
    font-size: 11vmin;
  }

  p, li {
    font-size: 8.5vmin;
    hyphens: none;
    text-align: left;
  }

  .capital-letter {
    font-size: 2.75em;
    margin-top: 0.35em;
    margin-bottom: 0.25em;
  }

  .capital-letter.mega {
    font-size: 4em;
    margin-top: 0.35em;
    margin-bottom: 0.25em;
  }

  .hero {
    height: 100vh;
  }

  .mint-button-container {
    padding: 1rem;
  }

  .mint-box {
    flex-direction: column;
  }

  .mint-box .characters .character-image {
    width: calc(100vw - (2rem + 12px));
    height: 90vw;
    margin-bottom: 1rem;
  }
}

.legal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.legal p {
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.5;
  margin: 0.25rem;
}

@media (max-width: 450px) {
  .call-to-action {
    flex-direction: column;
    max-width: calc(100vw - 2rem);
    right: 1rem;
    text-align: center;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    padding-top: 0.75rem;
  }

  .call-to-action .button {
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .call-to-action .subtitle {
    display: none;
  }
}


@keyframes shimmer {
  50% {
    mask-position:left
  }
  100% {
    mask-position: left;
  }
 }

.marquee-wrapper {
  max-width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  background-color: #67917e;
  font-size: 1.75rem;
  width: 150vw;
  padding-top: 0.25rem;
  z-index: 99;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 90s linear infinite;
}

.marquee span {
  display: inline-block;
}

.background-rocks {
  width: 100vw;
  height: 12.3vw;
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('./assets/background-rocks.png');
  margin-bottom: 2rem;
}

@keyframes rainbow {
  0% {
    color: #f6931d;
  }
  25% {
    color: #7fc6bd;
  }
  50% {
    color: #d58cb6;
  }
  75% {
    color: #e57261;
  }
  100% {
    color: #f6931d;
  }
}

@keyframes rainbow-background {
  0% {
    background-color: #f6931d;
  }
  25% {
    background-color: #7fc6bd;
  }
  50% {
    background-color: #d58cb6;
  }
  75% {
    background-color: #e57261;
  }
  100% {
    background-color: #f6931d;
  }
}

@keyframes rainbow-background-2 {
  0% {
    background-color: #7fc6bd;
  }
  25% {
    background-color: #f6931d;
  }
  50% {
    background-color: #e57261;
  }
  75% {
    background-color: #d58cb6;
  }
  100% {
    background-color: #7fc6bd;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}